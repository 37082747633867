.compose-form {
  &__warning {
    @apply text-xs mb-2.5 px-2.5 py-2 shadow-md rounded bg-secondary-400 text-white;

    strong {
      @apply font-medium;

      @each $lang in $cjk-langs {
        &:lang(#{$lang}) {
          @apply font-bold;
        }
      }
    }

    a {
      font-weight: 500;
      text-decoration: underline;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__modifiers {
    @apply text-gray-900 text-sm;
    font-family: inherit;
  }

  &__upload-wrapper { overflow: hidden; }

  &__uploads-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.contains-media {
      padding: 5px;
    }
  }

  &__upload {
    flex: 1 1 0;
    min-width: 40%;
    margin: 5px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    &__actions {
      @apply bg-gradient-to-b from-gray-900/80 via-gray-900/50 to-transparent flex items-start justify-between opacity-0 transition-opacity duration-100 ease-linear;

      &.active {
        @apply opacity-100;
      }

      .icon-button {
        @apply text-gray-200 hover:text-white text-sm font-medium p-2.5 space-x-1 rtl:space-x-reverse flex items-center;
      }
    }

    &-description {
      @apply bg-gradient-to-b from-transparent via-gray-900/50 to-gray-900/80 absolute z-[2px] bottom-0 left-0 right-0 p-2.5 opacity-0 transition-opacity duration-100 ease-linear;

      &.active {
        @apply opacity-100;
      }

      textarea {
        @apply bg-transparent text-white border-solid border border-white/25 p-2.5 rounded-md text-sm w-full m-0;

        &::placeholder {
          @apply text-white/60;
        }
      }
    }

    &-preview {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__upload-thumbnail {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 160px;
    width: 100%;
    overflow: hidden;
    position: relative;

    &.video {
      background-image: url('../assets/images/video-placeholder.png');
      background-size: cover;
    }

    &.audio {
      background-image: url('../assets/images/audio-placeholder.png');
      background-size: cover;
    }
  }
}

.privacy-dropdown {
  &.active {
    &.top .privacy-dropdown__value {
      @apply rounded-t-md;
    }

    .privacy-dropdown__dropdown {
      @apply block shadow-md;
    }
  }

  &__dropdown {
    @apply absolute bg-white dark:bg-gray-900 z-[1000] rounded-md shadow-lg ml-10 text-sm overflow-hidden;

    &.top {
      transform-origin: 50% 100%;
    }

    &.bottom {
      transform-origin: 50% 0;
    }
  }

  &__option {
    @apply flex p-2.5 text-sm text-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer;

    &.active {
      @apply bg-gray-100 dark:bg-gray-800;
    }

    &:hover,
    &.active {
      .privacy-dropdown__option__content,
      .privacy-dropdown__option__content strong {
        @apply text-black dark:text-white;
      }
    }

    &.active {
      @apply hover:bg-gray-200 dark:hover:bg-gray-700;
    }

    &__icon {
      @apply flex items-center justify-center mr-2.5 rtl:mr-0 rtl:ml-2.5;
    }

    &__content {
      @apply flex-auto text-primary-600 dark:text-primary-400;

      strong {
        @apply block font-medium text-black dark:text-white;

        @each $lang in $cjk-langs {
          &:lang(#{$lang}) {
            @apply font-bold;
          }
        }
      }
    }
  }
}
