.thumb-navigation {
  @apply fixed lg:hidden bottom-0 bg-white/90 dark:bg-gray-900/90 backdrop-blur-md border-t border-solid border-gray-300 dark:border-gray-800 left-0 right-0 shadow-2xl w-full flex z-50;
  padding-bottom: env(safe-area-inset-bottom); /* iOS PWA */
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #fff;

  &::-webkit-scrollbar {
    display: none;
  }

  &__link {
    @apply px-2 py-4 space-y-1 flex flex-col flex-1 items-center text-gray-600 text-lg;
  }
}
