.z-1000 {
  z-index: 1000;
}

.divide-x-dot > *:not(:last-child)::after {
  content: '•';
  padding-right: 4px;
  padding-left: 4px;
}

.mention {
  @apply text-primary-600 dark:text-accent-blue hover:underline;
}

.emoji-lg img.emojione {
  width: 36px !important;
  height: 36px !important;
}
