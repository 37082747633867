$media-compact-size: 50px;

.media-gallery {
  @apply rounded-lg;
  box-sizing: border-box;
  overflow: hidden;
  isolation: isolate;
  position: relative;
  width: 100%;
  height: auto;

  &__item {
    border: 0;
    box-sizing: border-box;
    display: block;
    float: left;
    position: relative;
    overflow: hidden;

    &__icons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .svg-icon {
        @apply h-24 w-24;
      }
    }

    &-overflow {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.75);
      z-index: 2;
      color: #333;
      text-align: center;
      font-weight: bold;
      font-size: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }

    &-thumbnail {
      @apply text-gray-400;
      cursor: zoom-in;
      display: block;
      text-decoration: none;
      line-height: 0;
      position: relative;
      z-index: 1;
      height: 100%;
      width: 100%;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__preview {
    @apply bg-gray-200 dark:bg-gray-900 rounded-lg;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    &--hidden {
      display: none;
    }
  }

  &__gifv {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  &__item-gifv-thumbnail {
    cursor: zoom-in;
    height: 100%;
    object-fit: cover;
    position: relative;
    width: 100%;
    z-index: 1;
    transform: none;
    top: 0;
  }

  &__gifv__label,
  &__filename__label,
  &__file-extension__label {
    @apply pointer-events-none absolute bottom-1.5 right-1.5 z-[1] block bg-black/50 py-0.5 px-1.5 text-white opacity-90 rounded-sm;
    font-size: 16px;
    font-weight: 500;
    transition: opacity 0.1s ease;
    line-height: 20px;
  }

  &__gifv {
    &.autoplay {
      .media-gallery__gifv__label {
        display: none;
      }
    }

    &:hover {
      .media-gallery__gifv__label {
        opacity: 1;
      }
    }
  }

  &--compact {
    height: $media-compact-size !important;
    background: transparent;

    .media-gallery__item {
      @apply rounded-lg;
      width: $media-compact-size !important;
      height: $media-compact-size !important;
      inset: auto !important;
      float: left !important;
      margin-right: 5px;

      &-overflow {
        font-size: 20px;
      }

      &__icons .svg-icon {
        @apply h-8 w-8;
      }
    }

    .media-gallery__file-extension__label {
      display: none;
    }
  }
}
