.status {
  @apply min-h-[54px] cursor-default;

  @supports (-ms-overflow-style: -ms-autohiding-scrollbar) {
    // Add margin to avoid Edge auto-hiding scrollbar appearing over content.
    // On Edge 16 this is 16px and Edge <=15 it's 12px, so aim for 16px.
    padding-right: 26px; // 10px + 16px
  }

  @keyframes fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  opacity: 1;
  animation: fade 150ms linear;
}

[column-type='filled'] .status__wrapper,
[column-type='filled'] .status-placeholder {
  @apply bg-transparent dark:bg-transparent rounded-none shadow-none;
}

.status-check-box {
  @apply flex items-center justify-between;

  .status-check-box__status {
    @apply py-2;

    .media-gallery {
      max-width: 250px;
    }

    .status__content {
      @apply p-0 text-gray-700 dark:text-gray-500 text-sm whitespace-normal;
    }

    .video-player,
    .audio-player {
      margin-top: 8px;
      max-width: 250px;
    }

    .media-gallery__item-thumbnail {
      cursor: default;
    }
  }
}

.status-check-box-toggle {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 10px;
}

.focusable:focus,
.focusable-within:focus-within {
  outline: 0; /* Required b/c HotKeys lib sets this outline */
  // @apply ring-2 ring-primary-300;
}

.status-card {
  @apply flex text-sm border border-solid border-gray-200 dark:border-gray-800 rounded-lg text-gray-800 dark:text-gray-200 no-underline;
}

a.status-card {
  @apply cursor-pointer hover:bg-gray-100 dark:hover:bg-primary-800/30 hover:no-underline;
}

.status-card-video,
.status-card-audio {
  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

.status-card__image {
  flex: 0 0 40%;
  position: relative;
  overflow: hidden;

  & > .svg-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    transform-origin: 50% 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      stroke-width: 1px;
    }
  }
}

.status-card.horizontal {
  display: block;
}

.status-card.compact {
  @apply border-gray-200 dark:border-gray-800;
}

.status-card__image-image {
  @apply block w-full h-full object-cover bg-cover bg-center;
}

.status-card--link {
  @apply flex flex-col md:flex-row overflow-hidden;
}

.material-status {
  padding-bottom: 10px;

  &__status {
    padding: 15px 0 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .status {
    padding: 8px 10px;

    &__content {
      padding-top: 10px;
    }
  }
}

.attachment-thumbs {
  position: relative;

  &__clickable-region {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
