.loading-indicator-wrapper {
  @apply h-screen w-screen flex justify-center items-center;
}

.loading-indicator {
  @apply text-white text-xs uppercase flex flex-col items-center justify-center overflow-visible;
}

.loading-indicator__container {
  @apply w-10 h-10 relative;
}

.loading-indicator__figure {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-12 h-12 rounded-full bg-transparent;
  border: 0 solid;
  border-width: 6px;
  border-color: #e5e7eb;
}

.loading-indicator-wrapper .loading-indicator {
  @apply h-screen w-screen items-center;

  &__figure {
    @apply border-gray-200;
  }
}

.load-more {
  @apply block w-full m-0 p-4 border-0 box-border text-gray-900 bg-transparent;

  .svg-icon {
    @apply mx-auto;
  }
}

.regeneration-indicator {
  @apply text-gray-900;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  cursor: default;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;

  @media screen and (max-width: 580px) {
    border-radius: 0;
  }

  & > div {
    width: 100%;
    background: transparent;
    padding-top: 0;
  }

  &__label {
    strong {
      @apply block mb-2.5 text-gray-900;
    }

    span {
      font-size: 15px;
      font-weight: 400;
    }
  }
}

.ptr,
.ptr__children {
  overflow: visible !important;
}
